// Lib imports
import Vue from 'vue';
import Router from 'vue-router';
import Meta from 'vue-meta';
import NProgress from 'nprogress';
import paths from './paths';

Vue.use(Router);

// Create a new router
const router = new Router({
  base: '/',
  mode: 'history',
  routes: paths,

  // This allows your SPA to act more like a traditional webpage, allowing the user to use back and forward buttons
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { selector: to.hash };
    }
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = !!localStorage.getItem('access_token');
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!isAuthenticated) {
      next({
        path: '/admin/login',
        // query: { redirect: to.fullPath }
      });
    } else {
      next(); // Allow access to other routes requiring authentication
    }
  } else {
    next(); // Allow access to routes that do not require authentication
  }
});

// N progress bar
router.beforeResolve((to, from, next) => {
  if (to.path) {
    NProgress.start();
  }
  next();
});

router.afterEach(() => {
  NProgress.done();
});

Vue.use(Meta);

export default router;
