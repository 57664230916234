<!-- @format -->

<!-- <nav-bar /> in the DashboardView.vue -->
<template>
	<!-- app-bar props below customize bevaviotr and looks -->
	<v-app-bar app color="background" :clipped-left="true">
		<!-- controls the sidebar colapsing-->
		<v-app-bar-nav-icon @click="drawerButton" />
		<router-link to="/">
			<v-app-bar-title>
				{{ title }}
			</v-app-bar-title>
		</router-link>
		<!-- <v-divider class="mx-4" inset vertical></v-divider> -->
		<!-- When placing a single v-spacer before or after the child components, 
		the components will push to the right and left of its container -->
		<v-spacer />
		<!-- <MaterialfyNotifications :buttonColor="btnColor" /> -->
		<!-- userSnippet.vue menu -->
		<div class="d-flex align-center pr-10">
			<v-btn icon :color="buttonColor" block @click="logout">
				Logout
				<v-icon>mdi-power</v-icon>
			</v-btn>
		</div>
		<MaterialfySettings :buttonColor="btnColor" />
	</v-app-bar>
</template>

<script>
// import MaterialfyUserSnippet from "@/components/materialfy/userSnippet"
import { mapActions, mapGetters } from 'vuex'
import network from "@/core/network"

export default {
	// components: {MaterialfyUserSnippet},
	name: 'AppBar',
	data() {
		return {
			//if the app is on mobile devices it changes the title
			title: this.$vuetify.breakpoint.mobile ? ' KOREA VISA PORTAL' : '',
			group: null,
			message: null,
			btnColor: 'tertiary',
			crdBottom: true,
			user: {}
		}
	},
	computed: {
		...mapGetters(['authorized', 'getAvatar']),
	},
	watch: {},
	created() { },
	beforeDestroy() { },
	mounted() {
		this.getProfile()
	},
	methods: {
		...mapActions(
			'drawertoggle', // chooses which namespaced state module to get the mutations from
			['drawerOn']
		),
		drawerButton() {
			// this calls the action drawerOn which then commits the toggle mutation
			this.drawerOn()
		},
		getProfile() {
			network.get('/users/profile').then(res => {
				console.log(res.data.data);
				this.user = res.data.data;
			}).catch(err => {
				console.log(err);
			})
		},
		logout() {
			localStorage.removeItem('access_token');
			localStorage.removeItem('role');
			localStorage.removeItem('user');
			this.$toast.open({
                        message: 'Phiên đăng nhập hết hạn',
                        type: 'error',
                        position: 'top',
                        duration:3000,
                      });   
			this.$router.push('/admin/login');


		},

	},
}
</script>

<style>
.v-app-bar-title__content {
	width: 280px;
}
</style>
