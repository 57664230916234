import axios from "axios";
const apiUrl = 'https://api.visa-kr.com/api/v1'
// -const token = localStorage.getItem('access_token');
// const apiUrl = 'http://localhost:8083/api/v1'
// const apiUrl = 'https://admin.mb-business.com'
// const apiUrl = 'https://oanh-be.pollygenz.com/v1/'
// const apiUrl = 'https://blog-study.onrender.com/v1/'
const token = localStorage.getItem('access_token');

export default {
	get(url, params) {
			return  axios.get(apiUrl + url, {
				params, 
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					'Cache-Control': 'no-cache',
					'Access-Control-Allow-Origin': '*',
					Authorization: `Bearer ${token}`
				},
			})
	},

	post(url, data) {

		return axios.post(apiUrl + url, data, {
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				'Cache-Control': 'no-cache',
				'Access-Control-Allow-Origin': '*',
				Authorization: `Bearer ${token}`
			},
			Authorization: `${token}`
		})
	},

	postNoneAuth(url, data) {
		return axios.post(apiUrl + url, data)
	},

	URI(url) {
		return apiUrl + url;
	},
    
	getImg(nameImg) {
		return apiUrl + '/image/upload/' + nameImg
	}

}
